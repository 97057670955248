import React from 'react';

import Divider, { DividerTone } from '@/components/switchback/Divider/Divider';

import css from './SectionWithDivider.module.css';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  divider?: boolean;
  dark?: boolean;
  log?: boolean;
}

const SectionWithDivider: React.FC<IProps> = ({
  children,
  dark,
  divider,
  className = '',
  ...props
}) => {
  const sectionMargin = !divider ? 'mb-6 md:mb-8' : '';

  return (
    <>
      <section className={`${sectionMargin} ${className} ${css.section}`} {...props}>
        {children}
      </section>
      {divider && (
        <Divider
          className={'my-6 md:my-8 col-content bg-grey-200'}
          tone={dark ? DividerTone.dark : DividerTone.normal}
        />
      )}
    </>
  );
};

export default SectionWithDivider;
