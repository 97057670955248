import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addToFavorites, removeFromFavorites } from '@/redux/modules/favorites';
import { getFlexibleDateRangeSelected } from '@/redux/selectors/queryParams';
import {
  trackListingFavoritedEvent,
  trackListingImageViewedEvent,
  trackListingSelectedEvent,
  trackListingUnfavoritedEvent,
} from '@/services/analytics/listings';
import { EListingSource } from '@/services/analytics/listings/types';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

import CarouselGrid from './Grid';

interface ICarouselProps {
  maxCols?: number;
  inline?: boolean;
  maxRentals?: number;
  verticalScrollGrid?: boolean;
  title?: string;
  loading?: boolean;
  rentalsList: IRentalTile[];
  extraQueryParams?: string;
  eventSource?: EListingSource;
  isRow?: boolean;
}

const RentalsCarousel: React.FC<ICarouselProps> = ({
  title,
  maxCols,
  maxRentals,
  verticalScrollGrid,
  loading,
  rentalsList,
  inline = false,
  extraQueryParams,
  eventSource,
  isRow = false,
}) => {
  const dispatch = useDispatch();
  const flexibleDateRangeSelected = useSelector(getFlexibleDateRangeSelected);

  const addFavorite = (rental: IRentalTile, index: number) => {
    if (rental?.id) {
      dispatch(addToFavorites(rental.id));
      if (eventSource) {
        trackListingFavoritedEvent(rental, index + 1, eventSource);
      }
    }
  };

  const removeFavorite = (rental: IRentalTile, index: number) => {
    if (rental?.id) {
      dispatch(removeFromFavorites(rental.id));
      if (eventSource) {
        trackListingUnfavoritedEvent(rental, index + 1, eventSource);
      }
    }
  };

  const onClickRental = (rental: IRentalTile, index: number) => {
    if (rental?.id && eventSource) {
      trackListingSelectedEvent({
        rental,
        eventSource,
        listingPlacement: index + 1,
        flexibleDateRange: flexibleDateRangeSelected,
      });
    }
  };

  const onChangeImage = (rental: IRentalTile, index: number, nextIndex: number) => {
    if (rental?.id && eventSource) {
      trackListingImageViewedEvent(rental, eventSource, index + 1, nextIndex);
    }
  };

  return (
    <CarouselGrid
      title={title}
      maxCols={maxCols}
      inline={inline}
      maxRentals={maxRentals}
      verticalScrollGrid={verticalScrollGrid}
      rentalsList={rentalsList}
      addFavorite={addFavorite}
      removeFavorite={removeFavorite}
      loading={Boolean(loading)}
      extraQueryParams={extraQueryParams}
      onClickRental={onClickRental}
      onChangeImage={onChangeImage}
      isRow={isRow}
    />
  );
};

export default RentalsCarousel;
