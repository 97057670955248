import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import Button, { ButtonShape, ButtonSize, ButtonVariants } from '../Button/Button';
import Icon from '../Icon/Icon';
import { ICONS, IconSize } from '../Icon/Icon.constants';
import css from './Accordion.module.css';

const CONSTANTS = {
  EXPAND_DURATION: 200,
};

interface IAccordionProps {
  children?: ReactNode;
  hideExtraInfoWhenExpanded?: boolean;
  'data-testid'?: string;
  expanded?: boolean;
  extraInfo?: ReactNode;
  id: string;
  label: ReactNode;
  onToggle?: (expanded: boolean) => void;
  panelClassName?: string;
  subhead?: ReactNode;
  expandDirection?: 'top' | 'bottom';
}

function Accordion({
  id,
  children,
  hideExtraInfoWhenExpanded,
  ['data-testid']: testid = 'accordion',
  expanded,
  extraInfo,
  label,
  panelClassName = '',
  onToggle,
  subhead,
  expandDirection = 'bottom',
}: IAccordionProps) {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [panelHeight, setPanelHeight] = useState<'auto' | number>(expanded ? 'auto' : 0);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setIsExpanded(current => {
        const newState = !current;
        setPanelHeight(newState ? 'auto' : 0);
        onToggle?.(newState);
        return newState;
      });
    },
    [onToggle],
  );

  useEffect(() => {
    setIsExpanded(expanded);
    setPanelHeight(expanded ? 'auto' : 0);
  }, [expanded]);

  const iconDirection =
    expandDirection === 'bottom'
      ? isExpanded
        ? 'rotate-90'
        : '-rotate-90'
      : isExpanded
        ? '-rotate-90'
        : 'rotate-90';

  return (
    <>
      <div
        className="flex flex-wrap items-center justify-between w-full text-gray-900"
        data-testid={testid}>
        <div className="flex flex-col items-start flex-1 mr-4">
          <label
            htmlFor={`accordion-button-${id}`}
            id={`accordion-label-${id}`}
            className="cursor-pointer autoType800">
            {label}
          </label>
          {!!subhead && (
            <div data-testid="accordion-subhead" className={`autoType200 mt-1 flex items-center`}>
              {subhead}
            </div>
          )}
        </div>
        <Button
          aria-controls={`accordion-panel-${id}`}
          aria-expanded={isExpanded}
          aria-labelledby={`accordion-label-${id}`}
          className={`md:ml-10 order-3 ${css.button}`}
          id={`accordion-button-${id}`}
          shape={ButtonShape.circle}
          onClick={handleClick}
          size={ButtonSize.small}
          variant={ButtonVariants.lightPrimaryOutlined}>
          <Icon
            className={`transform ${css.icon} ${iconDirection}`}
            name={ICONS.CARET_LARGE}
            size={IconSize.normal}
          />
        </Button>
        {!!extraInfo && (
          <div
            className={`autoType300 block order-4 md:order-1 w-full md:w-auto mt-3 md:mt-0 opacity-100 max-h-8 ${css.extraInfo}`}
            data-hidden-when-expanded={String(hideExtraInfoWhenExpanded)}>
            {extraInfo}
          </div>
        )}
      </div>
      <AnimateHeight
        id={`accordion-panel-${id}`}
        duration={CONSTANTS.EXPAND_DURATION}
        height={panelHeight}
        aria-labelledby={`accordion-button-${id}`}
        aria-hidden={!isExpanded}
        role="region">
        <div className={`pt-6 ${panelClassName}`}>{children}</div>
      </AnimateHeight>
    </>
  );
}

export default Accordion;
