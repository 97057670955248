import { ISeoContentItem } from '@/services/types/search/rentals/id';

export enum ESeoContent {
  COUNTY = 'county',
  LOCALITY = 'locality',
  PARK = 'park',
  CAMPGROUND = 'campground',
  CATEGORY = 'category',
  LINKS = 'links',
}

export interface ISeoContentRow {
  title: string;
  content: ISeoContentItem[] | undefined;
}

export interface ISeoContentRows {
  [ESeoContent.COUNTY]?: ISeoContentRow;
  [ESeoContent.LOCALITY]: ISeoContentRow;
  [ESeoContent.PARK]: ISeoContentRow;
  [ESeoContent.CAMPGROUND]: ISeoContentRow;
  [ESeoContent.CATEGORY]?: ISeoContentRow;
  [ESeoContent.LINKS]: ISeoContentRow;
}

export enum EStaySeoContent {
  LOCALITY = 'locality',
  PARK = 'park',
  CAMPGROUND = 'campground',
  LINKS = 'links',
}
