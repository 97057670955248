import React from 'react';
import { useSelector } from 'react-redux';

import { TRootState } from '@/redux/rootReducer';
import { getRentalsTiles, getSimilarRentalsTiles } from '@/redux/selectors/listing/similarRentals';
import { EListingSource } from '@/services/analytics/listings/types';

import RentalsCarousel from './RentalsCarousel';

interface ICarouselProps {
  maxCols?: number;
  maxRentals?: number;
  verticalScrollGrid?: boolean;
  inline?: boolean;
  title?: string;
  useRentalsFromId?: boolean;
  extraQueryParams?: string;
  eventSource?: EListingSource;
  isRow?: boolean;
}

const SimilarRentalsCarousel: React.FC<ICarouselProps> = ({
  title,
  maxCols,
  maxRentals,
  verticalScrollGrid = false,
  inline = false,
  useRentalsFromId = false,
  extraQueryParams,
  eventSource,
  isRow = false,
}) => {
  const rentals = useSelector(useRentalsFromId ? getSimilarRentalsTiles : getRentalsTiles);
  const isDataFetched = useSelector<TRootState, boolean>(state =>
    useRentalsFromId
      ? state.similarRentals.isFetched || state.rentals.isFetched
      : state.rentals.isFetched,
  );

  return (
    <RentalsCarousel
      title={title}
      maxCols={maxCols}
      maxRentals={maxRentals}
      verticalScrollGrid={verticalScrollGrid}
      inline={inline}
      rentalsList={rentals}
      loading={!isDataFetched}
      extraQueryParams={extraQueryParams}
      eventSource={eventSource}
      isRow={isRow}
    />
  );
};

export default SimilarRentalsCarousel;
