import { createSelector } from 'reselect';

import { ESeoContent, EStaySeoContent, ISeoContentRow } from '@/constants/seoContent';
import { TRootState } from '@/redux/rootReducer';
import { ILocation, ISeoContent } from '@/services/types/search/rentals/id';
import { getIntl } from '@/utility/i18n';

const mapDataToSeoContent = (data: ISeoContent | undefined, location: ILocation | undefined) => {
  if (!data || !location) {
    return undefined;
  }

  const intl = getIntl();
  const { city } = location;
  const content: Record<ESeoContent, ISeoContentRow> = {
    [ESeoContent.COUNTY]: {
      title: intl.formatMessage(
        {
          defaultMessage: 'RV rentals in counties near {city}',
          id: 'TRk4AO',
        },
        {
          city,
        },
      ),
      content: data[ESeoContent.COUNTY],
    },
    [ESeoContent.LOCALITY]: {
      title: intl.formatMessage(
        {
          defaultMessage: 'RVs for rent near {city}',
          id: 'Myy7bx',
        },
        {
          city,
        },
      ),
      content: data[ESeoContent.LOCALITY],
    },
    [ESeoContent.PARK]: {
      title: intl.formatMessage(
        {
          defaultMessage: 'Popular parks near {city}',
          id: 'HSWH4p',
        },
        {
          city,
        },
      ),
      content: data[ESeoContent.PARK],
    },
    [ESeoContent.CAMPGROUND]: {
      title: intl.formatMessage(
        {
          defaultMessage: 'Popular campgrounds near {city}',
          id: 'HxYhGi',
        },
        {
          city,
        },
      ),
      content: data[ESeoContent.CAMPGROUND],
    },
    [ESeoContent.CATEGORY]: {
      title: intl.formatMessage({
        defaultMessage: 'Rent a similar model RV',
        id: 'mxwZKo',
      }),
      content: data[ESeoContent.CATEGORY]?.length ? data[ESeoContent.CATEGORY] : undefined,
    },
    [ESeoContent.LINKS]: {
      title: intl.formatMessage({
        defaultMessage: 'Links',
        id: 'qCcwo3',
      }),
      content: data[ESeoContent.LINKS],
    },
  };

  return content;
};

const getListingSeoContent = createSelector<
  TRootState,
  ISeoContent | undefined,
  ILocation | undefined,
  ReturnType<typeof mapDataToSeoContent>
>(
  state => state.listing.data?.seo_content,
  state => state.listing.data?.location,
  mapDataToSeoContent,
);

export const getStayListingSeoContent = (
  state: TRootState,
): Record<EStaySeoContent, ISeoContentRow> | undefined => {
  const data = state.listing.data?.seo_content;
  const location = state.listing.data?.location;
  if (!data || !location) {
    return undefined;
  }

  const intl = getIntl();
  const { city } = location;
  const content: Record<EStaySeoContent, ISeoContentRow> = {
    [EStaySeoContent.LOCALITY]: {
      title: intl.formatMessage(
        {
          defaultMessage: 'Stays for rent near {city}',
          id: 'TIHUA4',
        },
        {
          city,
        },
      ),
      content: data[EStaySeoContent.LOCALITY],
    },
    [EStaySeoContent.PARK]: {
      title: intl.formatMessage(
        {
          defaultMessage: 'Popular parks near {city}',
          id: 'HSWH4p',
        },
        {
          city,
        },
      ),
      content: data[EStaySeoContent.PARK],
    },
    [EStaySeoContent.CAMPGROUND]: {
      title: intl.formatMessage(
        {
          defaultMessage: 'Popular campgrounds near {city}',
          id: 'HxYhGi',
        },
        {
          city,
        },
      ),
      content: data[EStaySeoContent.CAMPGROUND],
    },
    [EStaySeoContent.LINKS]: {
      title: intl.formatMessage({
        defaultMessage: 'Links',
        id: 'qCcwo3',
      }),
      content: data[EStaySeoContent.LINKS],
    },
  };

  return content;
};

export { getListingSeoContent, mapDataToSeoContent };
